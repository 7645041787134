<template>
    <div class="root">
        <div class="row mt-10" style="display:flex; justify-content: center;">
            <div class="col col-11 col-md-8" style="max-width:1000px;">
                <v-card elevation="0" outlined v-for="(u, index) in userList" :index="index" :key="index" class="my-2">
                    <v-card-text class=justify-center>
                        <div class="row">
                            <div class="col col-4">
                                <h2> <a :href="'/' + u.channel_name">{{u.channel_name}}</a> </h2>
                            </div>
                            <div class="col col-3">
                                <a @click="unfriend(u.user_id)"> unfriend </a>
                            </div>
                            <div class="col col-3">
                                <a v-if="!u.is_following" @click="follow(u.channel_name)"> follow </a>
                                <a v-if="u.is_following" @click="unfollow(u.channel_name)"> unfollow </a>
                            </div>
                            <div class="col col-2">
                                <ListenBell :channelName="u.channel_name" :refreshAllFriendData="refreshAllFriendData"/>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>  
</template>
<script>
import api from '/src/utils/api';

const FriendsList = {
    name: 'FriendsList',
    components: {
        ListenBell: () => import("/src/components/ListenBell"),
    },
    data() {
        return {
            userList: [],
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            api.getCall('get-friends').then(userList => {
                this.userList = userList;
            });
        },
        unfriend(friendUserId) {
            console.log("unfriend", friendUserId);
            api.postAuthRequired('unfriend', {friendUserId}).then(() => {
                this.refreshAllFriendData();
            });
        },
        follow(channelName) {
            api.postAuthRequired('follow-channel', {channelName}).then(() => {
                this.refreshAllFriendData();
            });
        },
        unfollow(channelName) {
            api.postAuthRequired('unfollow-channel', {channelName}).then(() => {
                this.refreshAllFriendData();
            });
        },
    },
    props: ['refreshAllFriendData'],
};

export default FriendsList;
</script>
